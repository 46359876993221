<template>
	<n-scrollbar class="notifications-list">
		<div
			class="item flex cursor-pointer"
			v-for="item of storeNotification.getNotifications()"
			:key="item.id"
			@click="item.action ? preformAction(item.id, item.action) :  setRead(item.id)"
			:class="[{ pointer: !!item.action }, item.type, item.read ? 'bg-gray-200' : '']"
		>
			<div class="icon-box" @click.stop="setRead(item.id)">
				<Icon :name="MessageIcon" :size="21" v-if="item.category === 'message'"></Icon>
				<Icon :name="CalendarIcon" :size="21" v-else-if="item.category === 'reminder'"></Icon>
				<Icon :name="AddIcon" :size="21" v-else-if="item.category === 'new'"></Icon>
				<Icon :name="AlertIcon" :size="21" v-else-if="item.category === 'alert'"></Icon>
				<n-tooltip trigger="hover" style="padding: 0" placement="right" v-if="!item.read">
					<template #trigger>
						<div class="read-badge" ></div>
					</template>
				</n-tooltip>
			</div>
			<div class="content grow">
				<div class="title" v-html="item.title"></div>
				<div class="description" v-html="item.description"></div>
				<div class="footer flex justify-between items-center">
					<div class="date">{{ item.date }}</div>
					<div class="action-text" v-if="!!item.action">{{ item.actionTitle || "Details" }}</div>
				</div>
			</div>
			<!-- <div class="check-btn" @click.stop="deleteOne(item.id)">
				<Icon :name="CheckIcon" :size="18"></Icon>
			</div> -->
		</div>
		<slot name="last"></slot>
		<n-empty v-if="!storeNotification.getNotifications().length" description="There is no notification" class="justify-center h-48" />
	</n-scrollbar>
</template>

<script lang="ts" setup>
import { NScrollbar, NTooltip, NEmpty } from "naive-ui"
import Icon from "@/components/common/Icon.vue"
import { useNotifications } from "@/composables/useNotifications"
import { computed } from "vue"
import _take from "lodash/take"
import { useNotificationStore } from "@/stores/apps/notification"
import { axiosToken } from "@/utils/http"

const CheckIcon = "carbon:checkmark"
const MessageIcon = "carbon:email"
const CalendarIcon = "carbon:calendar"
const NewsIcon = "fluent:news-24-regular"
const AlertIcon = "mdi:alert-outline"
const AddIcon = "carbon:add"

const props = defineProps<{
	maxItems?: number
}>()
const storeNotification = useNotificationStore()
// const list = storeNotification.getNotifications()

// const listSanitized = computed(() => {
// 	if (props.maxItems) {
// 		return _take(list.value, props.maxItems)
// 	}
// 	return list.value
// })

function preformAction(id: string | number, action: () => void) {
	action()
	setRead(id)

}

async function setRead(id: string | number) {

	await axiosToken("post","notifications/read/" + id + "/").then((response)=>{
		console.log(response);
	})
	storeNotification.setNotification(id)
	// useNotifications().setRead(id)
}

function deleteOne(id: string | number) {
	useNotifications().deleteOne(id)
}
</script>

<style lang="scss" scoped>
.notifications-list {
	.item {
		position: relative;
		padding: 14px 0;

		.icon-box {
			width: 70px;
			min-width: 70px;
			display: flex;
			justify-content: center;
			position: relative;

			.n-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				width: 42px;
				height: 42px;
				margin-top: 2px;
			}

			.read-badge {
				position: absolute;
				top: 5px;
				left: 14px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: var(--primary-color);
				cursor: pointer;
			}
		}

		.content {
			padding-right: 20px;
			font-size: 14px;

			.title {
				font-weight: bold;
			}

			.footer {
				margin-top: 6px;

				.date {
					font-size: 12px;
					opacity: 0.5;
				}
				.action-text {
					font-size: 12px;
				}
			}
		}

		.delete-btn {
			position: absolute;
			top: 8px;
			right: 8px;
			cursor: pointer;
			opacity: 0;
		}

		.check-btn {
			position: absolute;
			top: 8px;
			right: 8px;
			cursor: pointer;
			opacity: 0;
		}

		&.success {
			.icon-box {
				.n-icon {
					background-color: var(--primary-005-color);
					color: var(--success-color);
				}
			}
			.action-text {
				color: var(--success-color);
			}
		}
		&.info {
			.icon-box {
				.n-icon {
					background-color: var(--secondary1-opacity-010-color);
					color: var(--info-color);
				}
			}
			.action-text {
				color: var(--info-color);
			}
		}
		&.warning {
			.icon-box {
				.n-icon {
					background-color: var(--secondary3-opacity-010-color);
					color: var(--warning-color);
				}
			}
			.action-text {
				color: var(--warning-color);
			}
		}
		&.error {
			.icon-box {
				.n-icon {
					background-color: var(--secondary4-opacity-010-color);
					color: var(--error-color);
				}
			}
			.action-text {
				color: var(--error-color);
			}
		}

		&.pointer {
			cursor: pointer;
		}

		&:not(:last-child) {
			border-bottom: var(--border-small-050);
		}

		&:hover {
			background-color: var(--hover-005-color);

			.delete-btn {
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}
</style>
