import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { defineStore, acceptHMRUpdate } from "pinia"
import {type UTQData, type Challenge, type TeamRanking} from "@/stores/apps/interfaces/utq_interfaces"
import { useContentStore } from "./content";

export const useUTQStore = defineStore("utq", {
	state: () => ({utqData: {} as UTQData, ranking: [] as TeamRanking[], rankingChallenge: [] as TeamRanking[], challengeSelected: {} as Challenge}),
	actions: {
		async getUTQData (api_key:string)
		{
			await simpleAxios("get", "events/event_full/" + api_key, {})
			.then((response: AxiosResponse) => {
                this.setUTQData(response.data)
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setUTQData (data:any)
		{
			this.utqData = data
		},
		async getRankingChallenge(event_api_key:string)
		{
			await axiosToken("get", "UTQ/getRankingChallenge/" + event_api_key , {challenge_id: this.challengeSelected.id})
			.then((response: AxiosResponse) => {
				console.log(response.data)
				this.setRankingChallenge(response.data)
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		setChallengeSelected(challenge_id:number)
		{
			const storeContent = useContentStore()
			const challenge = storeContent.challenges.find((e:Challenge) => e.id == challenge_id)

			if(challenge != undefined)
			{
				this.challengeSelected = challenge
			}
		},
		setRankingChallenge(ranking:[])
		{
			this.rankingChallenge = []
			ranking.forEach((e:TeamRanking, index) => {
				const pos = index+1;
				const obj = {name: e.team.name_id + ". " + e.team.name, position: + pos + 'º', points: e.points }
				this.rankingChallenge.push(obj)
			});
		},
		async getRanking(api_key)
		{
			await simpleAxios("get", "UTQ/ranking_general/" + api_key)
			.then((response: AxiosResponse) => {
				this.setRanking(response.data)
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		setRanking(ranking:{})
		{
			console.log(ranking);
			
			this.ranking = []
			const new_ranking = []
			for (const [key, value] of Object.entries(ranking)){
				const obj:TeamRanking = {name: value.name, points: value.global_points, position: "0", full_name : value.name_id + ". " + value.name, id: parseInt(key)}

				const doneChallange = [];
				for (const [key2, value2] of Object.entries(value["challenge"]))
				{
					obj[String(key2)] = value2.position + "º / " + (value2.done ? value2.points : '-')
					doneChallange.push({id: key2, value: value2.done})
				}
				obj["challangeDone"] = doneChallange;
				new_ranking.push(obj)
			}
			new_ranking.sort((a, b) => parseFloat(a.points) - parseFloat(b.points))
			let index = 1;
			new_ranking.forEach(e => {
				e.position = index + "º";
				index++;
			});
			this.ranking = new_ranking
		},
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getSecondsTotalTime: (state) =>{
			if(state.utqData.start_time == undefined)
			{
				return;
			}
			return new Date(state.utqData.start_time)
		},
		getSecondsRoundRemaining: (state) =>{
			if(state.utqData.timer == undefined)
			{
				return;
			}
			return new Date(state.utqData.timer.finish_round_time)
		},
		getSecondsEventRemaining: state =>{
			if(state.utqData.timer == undefined)
			{
				return;
			}
			return (((new Date(state.utqData.timer.start_round_time).getTime()) +  (state.utqData.rounds - state.utqData.current_round) * state.utqData.round_duration * 1000))
		},
		getShowTimer: state =>{
			return state.utqData.show_timer
		},
		getChallengeRanking: state => (id:number)=>{
			let ranking_challenge =[]			
			for (const [key, value] of Object.entries(state.utqData.final_ranking)) {
				for (const [key2, value2] of Object.entries(value.challenge)) {
					if( key2 == id)
					{
						ranking_challenge.push({
							name:value.name,
							position: value2.position,
							points: value2.done ? value2.points : '-'
						})
					}
				}
			
			}
			const sortedData = ranking_challenge.sort((a, b) => a.position - b.position);
			
			return sortedData;
		},
		getGeneralRanking: state => ()=>{
			if(state.utqData.ranking_points)
			{
				return state.ranking.sort((a, b) => parseFloat(a.points) - parseFloat(b.points));
			}
			else
			{
				return state.ranking.sort(
					(a, b) => a.full_name.substring(0, parseFloat(a.full_name.indexOf("."))) - b.full_name.substring(0, parseFloat(b.full_name.indexOf("."))));
			}
		}



	}
})


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUTQStore, import.meta.hot))
}
